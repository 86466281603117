import React, { useState } from "react";
import { ButtonGroup } from "../../../components/Buttons/buttons";
import { SidebarWrapper } from "./landing.style";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Landing = () => {
  const [showLoginPopup, setShowLoginPopup] = React.useState(false);

  const handleCardClick = () => {
    setShowLoginPopup(true);
  };

  const handleClosePopup = () => {
    // Close the popup by updating the state
    setShowLoginPopup(false);
  };

  const handleRightButtonClick = () => {
    // Scroll to the Pricing Plans section
    const pricingSection = document.getElementById("pricing-section");
    pricingSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <SidebarWrapper>
      <div
        style={{
          backgroundImage: `linear-gradient(to top, rgba(42, 41, 204, 0) 0%, rgba(42, 41, 204, 0) 10%), url(${require("../../../image/opacity.png")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="flex justify-end md:pt-2 lg:pt-3 xl:pt-4"
          style={{ marginRight: "20px", marginTop: "10px" }}
        >
          <ButtonGroup
            leftButtonText={
              <Link
                to="/ethiomaps"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                Documentation
              </Link>
            }
            rightButtonText={
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "#FFFFFF" }}
              >
                Sign In
              </Link>
            }
            leftButtonStyle={{
              border: "1px solid #90BE6D",
              background: "#FFFFFF",
            }}
            rightButtonStyle={{ background: "#37371F", color: "#FFFFFF" }}
          />
        </div>

        {/* Add the ellipse shape */}
        <div className="py-16 flex flex-col items-center gap-4 bg-em-light cursor-default relative">
          <div className="w-full xl:max-w-4xl lg:max-w-3xl md:max-w-2xl sm:max-w-full flex flex-col items-center gap-2">
            <p className="text-5xl font-bold" style={{ color: "#37371F" }}>
              ETHIOMAPS
            </p>
            <h1 className="text-3xl font-semibold">Navigate Beyond Boundaries!</h1>   
          </div>
          <div className="grid grid-cols-12 max-w-7xl w-full xl:max-w-4xl lg:max-w-3xl md:max-w-2xl sm:max-w-full py-6 gap-y-20 relative pointer-events-none z-10">
            <div className="w-full absolute top-8">
              <img
                className="w-full object-cover pointer-events-none"
                src={require("../../../image/osm.png")}
                alt="Landing page map"
              />
            </div>
            <div className="col-span-6 flex justify-center">
              <div className="px-6 py-4 rounded-md bg-white shadow-sm text-sm font-semibold relative border border-gray-300 pointer-events-auto">
                <a href="#geocoding-section">Geocoding</a>
                <div
                  className="w-3 h-3 bottom-1 right-1 rounded-full absolute "
                  style={{ backgroundColor: "#90BE6D" }}
                ></div>
              </div>
            </div>
            <div className="col-span-6 flex justify-center">
              <div className="px-6 py-4 rounded-md bg-white shadow-sm text-sm font-semibold relative border border-gray-300 pointer-events-auto">
                <a href="#navigation-section">Navigation</a>
                <div
                  className="w-3 h-3 bottom-1 left-1 rounded-full absolute "
                  style={{ backgroundColor: "#90BE6D" }}
                ></div>
              </div>
            </div>
            <div className="col-span-6 flex mobile:pl-5 justify-start">
              <div className="px-6 py-4 mobile:px-1 md:py-4 mobile:h-14 mobile:w-32 rounded-md bg-white shadow-sm text-sm font-semibold relative border border-gray-300 pointer-events-auto">
                <a href="#advanced-search-section">Advanced Search</a>
                <div
                  className="w-3 h-3 top-1 right-1 rounded-full absolute "
                  style={{ backgroundColor: "#90BE6D" }}
                ></div>
              </div>
            </div>
            <div className="col-span-6 flex mobile:pr-5 justify-end">
              <div className="px-6 py-4 w-32 rounded-md bg-white shadow-sm text-sm font-semibold relative border border-gray-300 pointer-events-auto">
                <a href="#routing-section">Routing</a>
                <div
                  className="w-3 h-3 top-1 left-1 rounded-full absolute "
                  style={{ backgroundColor: "#90BE6D" }}
                ></div>
              </div>
            </div>
          </div>

          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <ButtonGroup
              leftButtonText={
                <Link to="/ethiomaps" className="text-white">
                  {" "}
                  Get Started{" "}
                </Link>
              }
              rightButtonText="Pricing Plan"
              leftButtonStyle={{ background: "#37371F", color: "#FFFFFF" }}
              rightButtonStyle={{
                border: " 1px solid #90BE6D",
                background: "#FFFFFF",
              }}
              onRightButtonClick={handleRightButtonClick}
            />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "-250px" }}>
        <h1 className="title" style={{ marginTop: " 300px" }}>
          Who are we?
        </h1>
        <p className="description">        
          Discover, navigate, and explore with EthioMaps,
          your ultimate map service provider. Let us guide you to
          new adventures with our cutting-edge technology and intuitive interfaces.
         
        </p>
      </div>

      <div>
        <h1 className="title">Our Services</h1>
        <p className="description">
          Browse through the comprehensive range of services we offer to enhance your navigation experience.
        </p>
      </div>

      {/* Our Services */}
      <div>
        {/* Card Set 1 */}
        <div
          className="flex flex-col md:flex-row justify-center items-start relative space-y-4 md:space-y-0 mb-12"
          id="advanced-search-section"
        >
          {/* Card on the left */}
          <div className="w-full md:w-2/5 h-64 bg-gray-300 rounded-3xl relative md:mr-4">
            <img
              src={require("../../../image/Search.png")}
              alt="Advanced Search"
              className="w-full h-full object-cover rounded-3xl"
            />
          </div>

          {/* Title and paragraph on the right */}
          <div className="w-full md:w-2/5 p-4 flex flex-col items-start">
            <h2 className="text-xl font-semibold">Advanced Search</h2>
            <br/>
            <p className="mb-4">
            Unlock the power of our advanced search feature, seamlessly integrated into our API offerings. 
            Effortlessly locate destinations with precision and ease,
             leveraging our cutting-edge technology to enhance your applications and services.
            </p>
            <Link to="/ethiomaps/advancedsearch">
              {" "}
              <button className="w-44 h-11 rounded-md bg-[#FAAB33] border-none text-white transition-colors duration-300 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-opacity-50">
                {" "}
                Read more{" "}
              </button>{" "}
            </Link>
          </div>
        </div>

        {/* Card Set 2 */}
        <div
          className="flex flex-col md:flex-row justify-center items-start relative space-y-4 md:space-y-0 mb-12 reverse-order"
          id="geocoding-section"
        >
          {/* Title and paragraph on the left */}
          <div className="w-full md:w-2/5 p-4 flex flex-col items-start">
            <h2 className="text-xl font-semibold">Geocoding</h2>
            <br/>
            <p className="mb-4">
            Discover the efficiency of our geocoding service, seamlessly integrated into our API offerings. 
            Convert addresses into geographic coordinates and vice versa with accuracy and speed, 
            empowering your applications with location intelligence.
            </p>
            <Link to="/ethiomaps/geocoding">
              <button className="w-44 h-11 rounded-md bg-[#FAAB33] border-none text-white transition-colors duration-300 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-opacity-50">
                Read more
              </button>{" "}
            </Link>
          </div>

          {/* Card on the right */}
          <div className="w-full md:w-2/5 h-64 bg-gray-300 rounded-3xl relative md:ml-4">
            <img
              src={require("../../../image/Geocoding.png")}
              alt="Geocoding"
              className="w-full h-full object-cover rounded-3xl"
            />
          </div>
        </div>

        {/* Card Set 3 */}
        <div
          className="flex flex-col md:flex-row justify-center items-start relative space-y-4 md:space-y-0 mb-12"
          id="navigation-section"
        >
          {/* Card on the left */}
          <div className="w-full md:w-2/5 h-64 bg-gray-300 rounded-3xl relative md:mr-4">
            <img
              src={require("../../../image/Navigation.png")}
              alt="Navigation"
              className="w-full h-full object-cover rounded-3xl"
            />
          </div>

          {/* Title and paragraph on the right */}
          <div className="w-full md:w-2/5 p-4 flex flex-col items-start">
            <h2 className="text-xl font-semibold">Navigation</h2>
            <br/>
            <p className="mb-4">
            Embark on journeys with confidence using our navigation service. Seamlessly integrated into our API offerings, 
            it provides accurate turn-by-turn directions, real-time traffic updates, 
            and alternate route suggestions, ensuring smooth and efficient travel experiences.
            </p>
            <Link to="/ethiomaps/navigation">
              {" "}
              <button className="w-44 h-11 rounded-md bg-[#FAAB33] border-none text-white transition-colors duration-300 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-opacity-50">
                Read more
              </button>{" "}
            </Link>
          </div>
        </div>

        {/* Card Set 4 */}
        <div
          className="flex flex-col md:flex-row justify-center items-start relative space-y-4 md:space-y-0 mb-12 reverse-order"
          id="routing-section"
        >
          {/* Title and paragraph on the left */}
          <div className="w-full md:w-2/5 p-4 flex flex-col items-start">
            <h2 className="text-xl font-semibold">Routing</h2>
            <br/>
            <p className="mb-4">
            Navigate your world seamlessly with our routing service. Integrated into our API offerings, 
            it provides optimized routes based on real-time data, 
            offering multiple transportation modes and customizable preferences for an efficient and personalized journey.
            </p>
            <Link to="/ethiomaps/routing">
              {" "}
              <button className="w-44 h-11 rounded-md bg-[#FAAB33] border-none text-white transition-colors duration-300 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-opacity-50">
                Read more
              </button>{" "}
            </Link>
          </div>

          {/* Card on the right */}
          <div className="w-full md:w-2/5 h-64 bg-gray-300 rounded-3xl relative md:ml-4">
            <img
              src={require("../../../image/Routing.png")}
              alt="Routing"
              className="w-full h-full object-cover rounded-3xl"
            />
          </div>
        </div>
      </div>

      <div>
        <h1 className="title" id="pricing-section">
          Pricing Plans
        </h1>
        <p className="description">
        Explore pricing plans for all, from individuals to businesses and developers.
        </p>
      </div>
      {/* Wrapped Cards Container */}
      <div className="flex flex-col md:flex-row justify-center items-center mt-16 space-y-6 md:space-y-0 md:space-x-6">
        {/* Left Card */}

        <div
          className="w-64 h-128 bg-white border border-gray-300 rounded-lg md:ml-3 p-6"
          onClick={handleCardClick}
        >
          <h2 className="font-bold text-lg text-center py-2">PRICING PLAN 2</h2>
          <div
            className="w-12 h-12  mx-auto"
            style={{ backgroundColor: "#2A29CC4F", borderRadius: "16px" }}
          ></div>
          <h4 className="font-medium text-base text-center mt-4">
            Included Features
          </h4>
          <ul className="font-normal text-sm text-left list-disc ml-6 mt-2">
            <li>1,000,000 advanced search requests</li>
            <li>100,000 routing requests</li>
            <li>500,000 geocoding requests</li>
            <li>100,000 navigation requests</li>
          </ul>
          <h2 className="font-bold text-lg text-center mt-4">ETB1,000/month</h2>
          <button
            className="w-full bg-blue-700 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded mt-4 mx-auto block transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
            style={{ backgroundColor: "#4062BB", border: "2px solid #4062BB" }}
          >
            Start Now
          </button>
        </div>

        {/* Middle Card */}

        <div
          className="w-72 h-160 bg-white border border-blue-500 rounded-lg relative md:mx-3 p-6 md:my-2"
          onClick={handleCardClick}
        >
          <div
            className="w-40 h-8 rounded-b-md absolute top-0 left-1/2 transform -translate-x-1/2 text-white flex items-center justify-center"
            style={{ background: "#161032", borderRadius: "0px 0px 25px 25px" }}
          >
            <h3 className="text-sm font-semibold">Recommended</h3>
          </div>

          <h2 className="font-bold text-lg text-center pt-8">PRICING PLAN 1</h2>
          <div
            className="w-12 h-12 mx-auto my-2"
            style={{ backgroundColor: "#2A29CC4F", borderRadius: "16px" }}
          ></div>

          <h4 className="font-medium text-base text-center mt-4">
            Included Features
          </h4>
          <ul className="font-normal text-base text-left list-disc ml-6 mt-2">
            <li>1,000,000 advanced search requests</li>
            <li>100,000 routing requests</li>
            <li>500,000 geocoding requests</li>
            <li>100,000 navigation requests</li>
          </ul>
          <h2 className="font-bold text-lg text-center mt-6">ETB1,000/month</h2>
          <button
            className="w-full bg-blue-700 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded mt-4 mx-auto block transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
            style={{ backgroundColor: "#4062BB" }}
          >
            Start Now
          </button>
        </div>

        {/* Right Card */}

        <div
          className="w-64 h-128 bg-white border border-gray-300 rounded-lg md:ml-3 p-6"
          onClick={handleCardClick}
        >
          <h2 className="font-bold text-lg text-center py-2">PRICING PLAN 3</h2>
          <div
            className="w-12 h-12  mx-auto"
            style={{ backgroundColor: "#2A29CC4F", borderRadius: "16px" }}
          ></div>
          <h4 className="font-medium text-base text-center mt-4">
            Included Features
          </h4>
          <ul className="font-normal text-sm text-left list-disc ml-6 mt-2">
            <li>1,000,000 advanced search requests</li>
            <li>100,000 routing requests</li>
            <li>500,000 geocoding requests</li>
            <li>100,000 navigation requests</li>
          </ul>
          <h2 className="font-bold text-lg text-center mt-4">ETB1,000/month</h2>
          <button
            className="w-full bg-blue-700 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded mt-4 mx-auto block transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
            style={{ backgroundColor: "#4062BB" }}
          >
            Start Now
          </button>
        </div>
      </div>

      {showLoginPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-120 bg-white p-10 rounded-lg relative">
            {/* Close icon */}
            <FontAwesomeIcon
              icon={faTimes}
              className="absolute top-4 right-4 text-gray-500 cursor-pointer"
              onClick={handleClosePopup}
            />

            {/* Login form content */}
            <h2 className="font-bold text-lg text-center mb-4">Login</h2>

            {/* Email input */}
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              className="w-full px-3 py-2 border mt-1 mb-3 rounded-md"
              placeholder="Enter your email"
              // Add state and onChange handler if needed
            />

            {/* Password input */}
            <label className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              className="w-full px-3 py-2 border mt-1 mb-3 rounded-md"
              placeholder="Enter your password"
              // Add state and onChange handler if needed
            />

            {/* Login button */}
            <button
              className="w-full bg-blue-700 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded mt-4"
              // Add onClick handler for login functionality
            >
              Login
            </button>
          </div>
        </div>
      )}

      {/* Footer */}
      <div
        className="w-full flex flex-col items-center py-2 sm:py-4 sm:px-4 px-2 mb-8"
        style={{
          backgroundColor: "#161032",
          borderRadius: "12px",
          margin: "30px auto",
          maxWidth: "1300px",
          marginTop: "80px",
        }}
      >
        <div className="w-full bg-em-accent grid grid-cols-12 sm:rounded-lg rounded-none py-2">
          <div className="py-2 px-8 col-span-12 sm:col-span-6 lg:col-span-4 flex flex-col text-white">
            <p className="font-bold text-gray-400 mb-1">Quick Links</p>
            <ul className="w-full px-4 gap-2 space-y-1">
              <li className="list-item list-disc text-sm">
                <a href="#advanced-search-section">Advanced Search</a>
              </li>
              <li className="list-item list-disc text-sm">
                <a href="#geocoding-section">Geocoding</a>
              </li>
              <li className="list-item list-disc text-sm">
                <a href="#navigation-section">Navigation</a>
              </li>
              <li className="list-item list-disc text-sm">
                <a href="#routing-section">Routing</a>
              </li>
            </ul>
          </div>
          <div className="py-2 px-8 col-span-12 sm:col-span-6 lg:col-span-4 flex flex-col text-white">
            <p className="font-bold text-gray-400 mb-1">Contact Us</p>
            <ul className="w-full px-4 gap-2 space-y-1">
              <li className="list-item text-sm">
                <h3 href="tel:">+251 900 000 000</h3>
              </li>
              <li className="list-item text-sm">
                <h3 href="tel:">+251 900 000 000</h3>
              </li>
              <li className="list-item text-sm">
                <a href="mailto:">mail@mailcom.com</a>
              </li>
            </ul>
          </div>
          <div className="col-span-12 lg:col-span-4 flex items-center justify-center flex-col p-2">
            <h2 className="font-bold text-4xl text-white">ETHIOMAPS</h2>
            <h3 className="text-white" href="www.ethiomaps.com">
              Navigate Beyond Boundaries
            </h3>
            {/* <div className="flex gap-2 mt-2">
                <FaFacebook />
                <FaLinkedin />
                <FaPaperPlane />
              </div> */}
          </div>
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Landing;
