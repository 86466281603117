import React, { useEffect, useState } from "react";
import { Table, Popconfirm, notification } from "antd";
import axios from "../../api";
import { connect } from "react-redux";

const Services = (props) => {
  const { loggedInUser } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.Services) {
      const newData = loggedInUser.Services.map((service) => ({
        name: service.name,
        key: service.id,
      }));
      setData(newData);
    }
  }, [loggedInUser]);

  const handleUnsubscription = async (key) => {
    try {
      // Send request to backend to unsubscribe
      const response = await axios.put(`http://localhost:3000/cancelSubscription/${props.loggedInUser.id}`, { serviceId: key });
      console.log('Subscription canceled:', response.data);
      
      // Update local state only if backend confirms successful unsubscription
      // Remove the service from the table
      const updatedData = data.filter((item) => item.key !== key);
      setData(updatedData);
      
      // Show notification
      notification.success({
        message: "Unsubscribe Successful",
        description: "The service has been successfully unsubscribed.",
      });
    } catch (error) {
      console.error('Error canceling subscription:', error);
      // Show error notification
      notification.error({
        message: "Unsubscribe Failed",
        description: "Failed to unsubscribe from the service. Please try again later.",
      });
    }
  };

  const columns = [
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
      width: 50,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <Popconfirm
          title="Sure to Unsubscribe?"
          onConfirm={() => handleUnsubscription(record.key)}
        >
          <a style={{ color: "red" }}>Unsubscribe</a>
        </Popconfirm>
      ),
      width: 50,
    }
  ];

  return (
    <div style={{ padding: "20px 80px 40px 80px" }} className=" h-dvh">
      <h1
        style={{
          textAlign: "center",
          padding: "20px",
          fontWeight: "bold",
          fontSize: "24px",
        }}
      >
        Services
      </h1>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

export default connect(mapStateToProps)(Services);
