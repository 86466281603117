import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import DashboardWidgetWrapper from "../Dashboard/widgets-wrapper";
import StickerWidget from "../Dashboard/sticker-widget";
import { PlusOutlined } from "@ant-design/icons";
import Project from "../NewProject/newproject";
import { Modal } from "antd";
import { Wrapper } from "./cards.style";
import { connect } from "react-redux";
import axios from "../../api";
import { updateApi } from "../../redux/auth/authState";
import * as ClipboardPolyfill from 'clipboard-polyfill'; 


const ProjectCard = (props) => {
  const { title, description, loggedInUser, updateApi } = props;
  const [notification, setNotification] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [copiedMessage, setCopiedMessage] = useState("");
  const [clickedButton, setClickedButton] = useState(null);

  const handleProject = () => {
    setIsModalVisible(true);
  };

  const regenerateKey = async () => {
    const response = await axios.put(
      `http://localhost:3000/regenerateAPIKey/${loggedInUser.id}`
    );
    updateApi(response.data.apiKey);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleCopy = (text, buttonId) => {
    ClipboardPolyfill.writeText(text).then( 
      () => {
        setCopiedMessage("Copied!");
        setTimeout(() => setCopiedMessage(""), 2000);
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );

   
    setClickedButton(buttonId);
    setTimeout(() => setClickedButton(null), 2000); 
  };


  const wisgetPageStyle = {
    display: "flex",
    width: "100%",
    flexFlow: "row wrap",
    alignItems: "flex-start",
    overflow: "hidden",
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setNotification(null);
    }, 3000);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeout);
  }, [notification, loggedInUser]);

  return (
    <div>
      <LayoutContentWrapper>
        <div style={wisgetPageStyle}>
          <Row gutter={0} style={{ width: "100%" }}>
            <Col lg={6} md={12} sm={24} xs={24} style={{ marginBottom: "2%" }}>
              <DashboardWidgetWrapper>
                <StickerWidget
                  title="Project Title 1"
                  text="Project description goes here"
                  fontColor="#FFFFFF"
                  bgColor="#2A29CC"
                  createdat="2:00 PM"
                />
              </DashboardWidgetWrapper>
            </Col>
            <Col lg={6} md={12} sm={24} xs={24} style={{ marginBottom: "2%" }}>
              <DashboardWidgetWrapper>
                <StickerWidget
                  title="Project Title 2"
                  text="Project description goes here"
                  fontColor="#000000"
                  bgColor="#FFFFFF"
                  createdat="3:00 PM"
                />
              </DashboardWidgetWrapper>
            </Col>
            <Col lg={6} md={12} sm={24} xs={24} style={{ marginBottom: "2%" }}>
              <DashboardWidgetWrapper>
                <div className="newproject" onClick={handleProject}>
                  <PlusOutlined
                    className="anticon"
                    style={{ color: "#6777CC" }}
                  />
                  <h3 style={{ color: "#6777CC" }}>Create Project </h3>
                </div>
              </DashboardWidgetWrapper>
            </Col>
          </Row>
        </div>
        <Modal
          title="Create Project"
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          <Project />
        </Modal>
      </LayoutContentWrapper>

      <Wrapper>
        <div className="geocontainer">
          <div className="geocard">
            <div>
              <div className="geotitle">{title}</div>
              <div className="geobgparagraph">{description}</div>

              <div className="subtitle">Project Title 1</div>
              <br />
              <h1>API KEY</h1>
              <div className="relative w-auto h-9 flex items-center justify-start bg-[#E7E4EE] rounded-md overflow-hidden">
                <div
                  className="flex items-center pr-2 overflow-x-auto scrollbar-thin samplereq"
                  style={{ height: "100%", width: "100%" }}
                >
                  <span className="text-sm text-black whitespace-nowrap ml-2 max-w-xs">
                    {loggedInUser.apiKey}
                  </span>
                </div>
                <button
                  className={`w-16 h-9 bg-[#6777CC] text-white text-sm flex items-center justify-center rounded-md ml-auto ${
                    clickedButton === "apiButton" ? "border border-green-500" : ""
                  }`}
                  onClick={() =>
                    handleCopy(loggedInUser.apiKey, "apiButton")
                  }
                >
                {clickedButton === "apiButton" ? "Copied!" : "Copy"}
                </button>
              </div>
              <button
                className="h-8 w-32 bg-[#2A29CC] text-white rounded-md mt-1 flex items-center justify-center"
                onClick={regenerateKey}
              >
                Regenerate Key
              </button>

              <div className="subtitle">Preference</div>
              <div className="relative h-52 w-auto flex flex-col items-start justify-start bg-[#E7E4EE] rounded-md p-4 overflow-hidden mt-6">
                <div className="relative w-full ">
                  <div>
                    Selected Project
                    <p className="font-bold text-lg">Project Title 1</p>
                  </div>
                </div>
                <div className="relative w-full ">
                  <div>
                    {" "}
                    Real-time location Sharing
                    <span style={{ marginRight: "100px" }}>Bookmarks</span>
                  </div>
                </div>
              </div>

              <div className="subtitle">Activity</div>
              <div className="relative  h-40 w-auto  flex items-start justify-start bg-[#E7E4EE] rounded-md p-2 overflow-hidden mt-6 "></div>

              <div className="subtitle">Billing & Usage</div>
              <div className="relative  h-40 w-auto  flex items-start justify-start bg-[#E7E4EE] rounded-md p-2 overflow-hidden mt-6 "></div>
            </div>
            <div className="footer"></div>
          </div>

          {/* Notification */}
          {notification && (
            <div
              type="success"
              position="top-right"
              onClose={() => setNotification(null)}
            >
              {notification}
            </div>
          )}
        </div>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};
const mapDispatchToProps = {
  updateApi: updateApi,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectCard);
