import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../components/utility/logo";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import AppsIcon from "@mui/icons-material/Apps";
import SearchIcon from "@mui/icons-material/Search";
import NearMeIcon from "@mui/icons-material/NearMe";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import KeyIcon from "@mui/icons-material/Key";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import { connect } from "react-redux";

const Sidebar = ({ isLoggedIn }) => {
  const location = useLocation();
  const selectedItem = location.pathname.split("/").pop();
  return (
    <div className=" h-screen p-1 w-48 fixed">
      <div className="flex justify-center mb-9 mt-5">
        <Link to={"/"}>
          <Logo style={{ height: "16px" }} />
        </Link>
      </div>

      <ul>
        {/* Dashboard */}
        <li
          className={`py-1 mb-1 hover:bg-[#E7E4EE] hover:rounded-xl hover:px-2 hover:w-48 ${
            selectedItem === "dash12" &&
            "bg-[#2A29CC] rounded-xl px-3 w-48 text-white"
          } ${!isLoggedIn ? "hidden" : ""}`}
        >
          <Link to={"dash12"} className="flex items-center">
            <span className="mr-3">
              <AppsIcon className="text-lg" />
            </span>{" "}
            Dashboard
          </Link>
        </li>
        <li
          className={`py-1 mb-1 hover:bg-[#E7E4EE] hover:rounded-xl hover:px-2 hover:w-48 ${
            selectedItem === "projects" &&
            "bg-[#2A29CC] rounded-xl px-3 w-48 text-white"
          }  ${!isLoggedIn ? "hidden" : ""}`}
        >
          <Link to={"projects"} className="flex items-center">
            <span className="mr-3 ">
              <KeyIcon />
            </span>{" "}
            Projects & API Key
          </Link>
        </li>
        <li
          className={`py-1 mb-1 hover:bg-[#E7E4EE] hover:rounded-xl hover:px-2 hover:w-48 ${
            selectedItem === "services" &&
            "bg-[#2A29CC] rounded-xl px-3 w-48 text-white"
          }  ${!isLoggedIn ? "hidden" : ""}`}
        >
          <Link to={"services"} className="flex items-center">
            <span className="mr-3">
              <MiscellaneousServicesIcon />
            </span>{" "}
            Services
          </Link>
        </li>

        {/* Documentation */}
        <li>
          <h2
            style={{
              fontSize: "20px",
              color: "#6777CC",
              fontWeight: "bold",
            }}
            className="mt-5 mb-5"
          >
            Documentation
          </h2>
        </li>
        <li
          className={`py-1 mb-1 hover:bg-[#E7E4EE] hover:rounded-xl hover:px-2 hover:w-48 ${
            selectedItem === "documentation" &&
            "bg-[#2A29CC] rounded-xl px-3 w-48 text-white"
          }  ${
            selectedItem === "ethiomaps" &&
            "bg-[#2A29CC] rounded-xl px-3 w-48 text-white"
          } `}
        >
          <Link to={"documentation"} className="flex items-center">
            <span className="mr-3">
              <EmojiObjectsOutlinedIcon />
            </span>{" "}
            Introduction
          </Link>
        </li>
        <li
          className={`py-1 mb-1 hover:bg-[#E7E4EE] hover:rounded-xl hover:px-2 hover:w-48 ${
            selectedItem === "advancedsearch" &&
            "bg-[#2A29CC] rounded-xl px-3 w-48 text-white"
          }`}
        >
          <Link to={"advancedsearch"} className="flex items-center">
            <span className="mr-3">
              <SearchIcon />
            </span>{" "}
            Advanced Search
          </Link>
        </li>
        <li
          className={`py-1 mb-1 hover:bg-[#E7E4EE] hover:rounded-xl hover:px-2 hover:w-48 ${
            selectedItem === "geocoding" &&
            "bg-[#2A29CC] rounded-xl px-3 w-48 text-white"
          }`}
        >
          <Link to={"geocoding"} className="flex items-center">
            <span className="mr-3">
              <MultipleStopIcon />
            </span>{" "}
            Geocoding
          </Link>
        </li>
        <li
          className={`py-1 mb-1 hover:bg-[#E7E4EE] hover:rounded-xl hover:px-2 hover:w-48 ${
            selectedItem === "navigation" &&
            "bg-[#2A29CC] rounded-xl px-3 w-48 text-white"
          }`}
        >
          <Link to={"navigation"} className="flex items-center">
            <span className="mr-3">
              <NearMeIcon />
            </span>{" "}
            Navigation
          </Link>
        </li>
        <li
          className={`py-1 mb-1 hover:bg-[#E7E4EE] hover:rounded-xl hover:px-2 hover:w-48 ${
            selectedItem === "routing" &&
            "bg-[#2A29CC] rounded-xl px-3 w-48 text-white"
          }`}
        >
          <Link to={"routing"} className="flex items-center">
            <span className="mr-3">
              <AltRouteIcon />
            </span>{" "}
            Routing
          </Link>
        </li>
      </ul>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.idToken !== null ? true : false,
  };
}

export default connect(mapStateToProps)(Sidebar);
