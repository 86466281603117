import React, { Component } from "react";
import Card from "../../components/Cards/cards.js";
// import Logo from "../../components/utility/logo";
class DistanceMatrix extends Component {
  render() {
    const responses = {
      200: {
        totalDistance: 471,
        msg: "ok DistanceMatrix,",
      },
      400: {
        totalDistance: 71,
        msg: "- DistanceMatrix",
      },
      500: {
        totalDistance: 1,
        msg: "- DistanceMatrix",
      },
    };

    const codes = {
      html: {
        status: "DistanceMatrix",
        data: [
          {
            service_id: "12345",
            service_name: "Arat Kilo Service 1",
            description: "This is a sample service at Arat Kilo.",
            location: "Arat Kilo, Addis Ababa",
            rating: 4.5,
            reviews: 20,
            category: "Food",
            price_range: "$$",
            hours_of_operation: "Mon-Sun: 9:00 AM - 10:00 PM",
            contact: "Phone: (123) 456-7890",
            website: "http://www.sample-service.com",
          },
          {
            service_id: "67890",
            service_name: "Arat Kilo Service 2",
            description: "Another sample service in Arat Kilo.",
            location: "Arat Kilo, Addis Ababa",
            rating: 4.0,
            reviews: 15,
            category: "Retail",
            price_range: "$$$",
            hours_of_operation: "Mon-Sat: 10:00 AM - 8:00 PM",
            contact: "Phone: (234) 567-8901",
            website: "http://www.another-service.com",
          },
          {
            service_id: "1",
            service_name: "Service 1",
            description: "This is service 1.",
            location: "Location 1",
            rating: 4.5,
            reviews: 20,
            category: "Category A",
            price_range: "$$",
            hours_of_operation: "Mon-Sun: 9:00 AM - 10:00 PM",
            contact: "Phone: (123) 456-7890",
            website: "http://www.service1.com",
          },
          {
            service_id: "2",
            service_name: "Service 2",
            description: "This is service 2.",
            location: "Location 2",
            rating: 4.0,
            reviews: 15,
            category: "Category B",
            price_range: "$$$",
            hours_of_operation: "Mon-Sat: 10:00 AM - 8:00 PM",
            contact: "Phone: (234) 567-8901",
            website: "http://www.service2.com",
          },
          // Continue adding more entries here...
          {
            service_id: "421",
            service_name: "Service 421",
            description: "This is service 421.",
            location: "Location 421",
            rating: 3.5,
            reviews: 10,
            category: "Category D",
            price_range: "$$$",
            hours_of_operation: "Mon-Fri: 8:00 AM - 6:00 PM",
            contact: "Phone: (987) 654-3210",
            website: "http://www.service421.com",
          },
        ],
      },
      javascript: {
        totalDistance: 71,
        msg: "- DistanceMatrix",
      },
    };

    return (
      <Card
        title="Distance Matrix"
        description="The DistanceMatrix will include: search suggestions, autocomplete Search by service area, Search nearby services, search by category, combined search, search by coordinates, Local information "
        apiButtonText="http://69.243.101.217:3003/services?API_KEY=8157fe6e-4bb0-440c-aee5-15f992959789&service=search&text=Bulbula"
        samplereqText="http://69.243.101.217:3003/services?API_KEY=8157fe6e-4bb0-440c-aee5-15f992959789&service=routing&text=38.764152,9.034507;38.760133,8.133834"
        responses={responses}
        codes={codes}
      />
    );
  }
}

export default DistanceMatrix;
